import { OutboundLink } from 'gatsby-plugin-google-analytics';
import React from 'react';
import { AiFillHome, AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import { FaAirbnb, FaExternalLinkSquareAlt } from 'react-icons/fa';

import AspectBox from '../../../components/AspectBox';
import PrismicRichText from '../../../components/PrismicRichText';
import { Experience } from '../../../types/experience';

import './ExperienceInfo.scss';

const secondaryLinkIcon = (label: string): React.ReactNode => {
  if (label === 'Instagram') return <AiFillInstagram />;
  if (label === 'YouTube') return <AiFillYoutube />;
  if (label === 'Airbnb') return <FaAirbnb />;
  return <FaExternalLinkSquareAlt />;
};

const currencyLabel = (currency: string): string => {
  if (currency === 'BRL') return 'R$';
  if (currency === 'USD') return 'US$';
  if (currency === 'EUR') return '€';
  return currency;
};

const addressParts = (location: Experience['location']): string[] => {
  const addressParts: string[] = [];
  const { address, country, state, city } = location;
  if (address) addressParts.push(address);
  if (city) addressParts.push(city);
  if (state) addressParts.push(state);
  if (country) addressParts.push(country);
  return addressParts;
};

const ExperienceInfo: React.FC<{ experience: Experience }> = ({ experience }) => {
  const fullAddress = addressParts(experience.location).join(' - ');

  return (
    <div className="experience-info">
      <div className="container">
        <div className="experience-info-main">
          <h2>{experience.name}</h2>

          {(experience.links.main || experience.links.secondary) && (
            <ul>
              {experience.links.main && (
                <li>
                  <OutboundLink href={experience.links.main[1]} target="_blank" rel="noopener noreferrer">
                    <AiFillHome />
                    {experience.links.main[0]}
                  </OutboundLink>
                </li>
              )}

              {experience.links.secondary && experience.links.secondary.map((link) => (
                <li key={link[1]}>
                  <OutboundLink href={link[1]} target="_blank" rel="noopener noreferrer">
                    {secondaryLinkIcon(link[0])}
                    {link[0]}
                  </OutboundLink>
                </li>
              ))}
            </ul>
          )}
        </div>

        {(experience.pricing.average || experience.pricing.info) && (
          <div className="experience-info-pricing">
            <h3>
              Quanto Custa?
            </h3>

            {experience.pricing.average && (
              <p>
                <strong>
                  {currencyLabel(experience.pricing.currency)}
                  {experience.pricing.average.toLocaleString()}
                  {experience.pricing.unit && ` / ${experience.pricing.unit}`}
                </strong>
              </p>
            )}

            {experience.pricing.info && (
              <PrismicRichText content={experience.pricing.info} />
            )}

            {experience.pricing.average && (
              <i>Os preços indicados são apenas uma referência e estão sujeitos a mudança a qualquer momento.</i>
            )}
          </div>
        )}

        {(experience.location.address || experience.location.city || experience.location.state) && (
          <div className="experience-info-location">
            <h3>
              Onde Fica?
            </h3>

            <p>{fullAddress}</p>

            {(experience.location.address || (experience.location.city && experience.location.state)) && (
              <AspectBox heightRatio={1 / 0.5}>
                <iframe
                  title="Mapa"
                  src={`https://maps.google.com.br/maps?q=${encodeURIComponent(fullAddress)}&output=embed&hl=pt-BR&z=10`}
                  scrolling="no"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  loading="lazy"
                />
              </AspectBox>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperienceInfo;
