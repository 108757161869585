import PhotoSwipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import React, { useEffect, useState } from 'react';
import { AiOutlineZoomIn } from 'react-icons/ai';
import { FaImages } from 'react-icons/fa';

import { PrismicItemRichText } from '../../types/prismic';
import { buildImgixUrlWithAspectRatio } from '../../util/images';
import { richTextAsString } from '../../util/rich-text-as-string';
import AspectBox from '../AspectBox';
import Img from '../Img';
import PrismicRichText from '../PrismicRichText';

import 'photoswipe/dist/default-skin/default-skin.css';
import 'photoswipe/dist/photoswipe.css';
import './Gallery.scss';

// TODO: need something like richTextAsHTML
const galleryItemDescriptionAndAttribution = (
  description: PrismicItemRichText[] | undefined,
  attribution: PrismicItemRichText[] | undefined,
): string | undefined => {
  const content: string[] = [];

  if (description) {
    const descriptionAsString = richTextAsString(description);
    if (descriptionAsString) content.push(descriptionAsString);
  }

  if (attribution) {
    const attributionAsString = richTextAsString(attribution);
    if (attributionAsString) content.push(`Foto: ${attributionAsString}`);
  }

  if (content.length > 0) return content.join(' ');
  return undefined;
};

const Gallery: React.FC<{
  title?: string;
  attribution?: PrismicItemRichText[];
  items: {
    url: string;
    width: number;
    height: number;
    description?: PrismicItemRichText[];
    attribution?: PrismicItemRichText[];
  }[];
}> = ({ title, attribution, items }) => {
  const [activeIndex, setActiveIndex] = useState<number>();

  useEffect(() => {
    if (activeIndex !== undefined) {
      const gallery = new PhotoSwipe(
        document.getElementById('photo-swipe-dom') as HTMLElement,
        PhotoswipeUIDefault,
        items.map((item) => ({
          src: item.url,
          w: item.width,
          h: item.height,
          title: galleryItemDescriptionAndAttribution(item.description, item.attribution),
        })),
        {
          index: activeIndex,
          history: false,
          errorMsg: 'Erro carregando a imagem :(',
          bgOpacity: 0.97,
          closeOnScroll: false,
          shareEl: false,
          clickToCloseNonZoomable: false,
          tapToClose: false,
          closeElClasses: [],
        },
      );

      gallery.init();

      gallery.listen('close', () => setActiveIndex(undefined));
    }
  }, [activeIndex]);

  const handleItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number): void => {
    event.preventDefault();
    setActiveIndex(index);
  };

  return (
    <div className="gallery">
      {title && (
        <h2>
          <FaImages />
          {title}
        </h2>
      )}

      <ul>
        {items.map((item, index) => (
          <li key={item.url}>
            <AspectBox heightRatio={1}>
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event): void => handleItemClick(event, index)}
              >
                <Img
                  src={buildImgixUrlWithAspectRatio(item.url, 1, 500)}
                />

                <span className="gallery-zoom">
                  <span>
                    <AiOutlineZoomIn />
                  </span>
                </span>
              </a>
            </AspectBox>
          </li>
        ))}
      </ul>

      {attribution && (
        <div className="gallery-attr">
          {'Fotos: '}
          <PrismicRichText content={attribution} />
        </div>
      )}
    </div>
  );
};

export default Gallery;
export { default as GalleryDom } from './gallery-dom';
