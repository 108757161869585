import { Link, PageProps } from 'gatsby';
import React from 'react';
import { FaCheck } from 'react-icons/fa';

import AspectBox from '../../components/AspectBox';
import ExperienceList from '../../components/ExperienceList';
import Gallery, { GalleryDom } from '../../components/Gallery';
import Img from '../../components/Img';
import Layout from '../../components/Layout';
import PrismicRichText from '../../components/PrismicRichText';
import { ExperiencePreview, Experience as ExperienceType } from '../../types/experience';
import { buildImgixUrlWithAspectRatio } from '../../util/images';

import ExperienceInfo from './ExperienceInfo';
import ExperienceVideos from './ExperienceVideos';

import './Experience.scss';

export interface ExperiencePageContext {
  experience: ExperienceType;
  moreExperiences: ExperiencePreview[];
}

const mainPhotoAspectRatio = 1 / 0.65;
const mainPhotoMaxWidth = 900;

const Experience: React.FC<PageProps<unknown, ExperiencePageContext>> = ({ pageContext: { experience, moreExperiences } }) => (
  <Layout
    mainClassName="experience"
    title={experience.title}
    meta={{
      description: experience.description,
      image: experience.mainPhoto.landscape.url,
    }}
  >
    <div className="experience-heading">
      <div className="experience-heading-container">
        <AspectBox heightRatio={mainPhotoAspectRatio}>
          <Img
            src={buildImgixUrlWithAspectRatio(experience.mainPhoto.landscape.url, mainPhotoAspectRatio, mainPhotoMaxWidth)}
          />
        </AspectBox>

        <div className="experience-heading-title">
          <h2>{experience.title}</h2>
          {experience.name && <h3>{experience.name}</h3>}

          <ul>
            {experience.categories.map((category) => (
              <React.Fragment key={category.path}>
                <li>
                  <Link to={category.path}>
                    {category.name}
                  </Link>
                </li>

                {category.subcategories.map((subcategory) => (
                  <li key={subcategory.path}>
                    <Link to={subcategory.path}>
                      {subcategory.title ?? subcategory.name}
                    </Link>
                  </li>
                ))}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>

    <div className="experience-description">
      <div className="container">
        <PrismicRichText content={experience.description} />

        {(experience.kidFriendly || experience.petFriendly) && (
          <p>
            {experience.kidFriendly && (
              <span>
                <FaCheck /> Crianças são bem-vindas!
              </span>
            )}

            {experience.petFriendly && (
              <span>
                <FaCheck /> Pets são bem-vindos!
              </span>
            )}
          </p>
        )}
      </div>
    </div>

    <div className="experience-galleries">
      <div className="container">
        {experience.galleries.map((gallery, index) => (
          <Gallery
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={gallery.title ?? 'Fotos'}
            attribution={gallery.attribution}
            items={gallery.items}
          />
        ))}
      </div>
    </div>

    <ExperienceVideos videos={experience.videos} />

    <ExperienceInfo experience={experience} />

    <div className="experience-more">
      <div className="container">
        <h2>Outras Experiências Incríveis:</h2>

        <ExperienceList experiences={moreExperiences} variant="light" />
      </div>
    </div>

    <GalleryDom />
  </Layout>
);

export default Experience;
