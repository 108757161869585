import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaVideo } from 'react-icons/fa';

import AspectBox from '../../../components/AspectBox';
import PrismicRichText from '../../../components/PrismicRichText';
import { Experience } from '../../../types/experience';

import './ExperienceVideos.scss';

const ExperienceVideos: React.FC<{
  videos: Experience['videos'];
}> = ({ videos }) => {
  const [activeVideo, setActiveVideo] = useState(0);

  if (videos.length === 0) return null;

  const handlePrevNextClick = (offset: number): void => {
    setActiveVideo((activeVideo) => activeVideo + offset);
    // scroll into view somehow?
  };

  return (
    <div className="experience-videos">
      <h2>
        <FaVideo />
        Vídeos
      </h2>

      {videos.length > 1 && (
        <span>
          <strong>{activeVideo + 1}</strong> / <strong>{videos.length}</strong>
        </span>
      )}

      <div className="container">
        {videos.length > 1 && (
          <button
            type="button"
            title="Vídeo Anterior"
            onClick={(): void => handlePrevNextClick(-1)}
            disabled={activeVideo === 0}
          >
            <FaChevronLeft />
          </button>
        )}

        {videos[activeVideo].provider_name === 'YouTube' || videos[activeVideo].provider_name === 'Vimeo' ? (
          <AspectBox heightRatio={16 / 9}>
            <PrismicRichText
              content={[{
                type: 'embed',
                oembed: videos[activeVideo],
              }]}
            />
          </AspectBox>
        ) : (
          <PrismicRichText
            content={[{
              type: 'embed',
              oembed: videos[activeVideo],
            }]}
          />
        )}

        {videos.length > 1 && (
          <button
            type="button"
            title="Próximo Vídeo"
            onClick={(): void => handlePrevNextClick(1)}
            disabled={activeVideo === (videos.length - 1)}
          >
            <FaChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default ExperienceVideos;
