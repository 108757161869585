/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

const GalleryDom = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div id="photo-swipe-dom" className="pswp" tabIndex={-1} role="dialog" aria-hidden="true" ref={ref}>
    <div className="pswp__bg" />
    <div className="pswp__scroll-wrap">
      <div className="pswp__container">
        <div className="pswp__item" />
        <div className="pswp__item" />
        <div className="pswp__item" />
      </div>

      <div className="pswp__ui pswp__ui--hidden">
        <div className="pswp__top-bar">
          <div className="pswp__counter" />
          <button className="pswp__button pswp__button--close" title="Fechar (Esc)" />
          <button className="pswp__button pswp__button--fs" title="Tela cheia" />
          <button className="pswp__button pswp__button--zoom" title="Zoom (mais/menos)" />

          <div className="pswp__preloader">
            <div className="pswp__preloader__icn">
              <div className="pswp__preloader__cut">
                <div className="pswp__preloader__donut" />
              </div>
            </div>
          </div>
        </div>

        <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div className="pswp__share-tooltip" />
        </div>

        <button className="pswp__button pswp__button--arrow--left" title="Anterior" />
        <button className="pswp__button pswp__button--arrow--right" title="Próxima" />

        <div className="pswp__caption">
          <div className="pswp__caption__center" />
        </div>
      </div>
    </div>
  </div>
));

export default GalleryDom;
